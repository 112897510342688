@import url("https://fonts.googleapis.com/css2?family=Geist+Mono:wght@100..900&family=Host+Grotesk:ital,wght@0,300..800;1,300..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Rubik:ital,wght@0,300..900;1,300..900&family=Sour+Gummy:ital,wght@0,100..900;1,100..900&display=swap");
@font-face {
  font-family: "Sawah";
  src: url("./assets/sawah.ttf");
}
@font-face {
  font-family: "Vonique64";
  src: url("./assets/Vonique64.ttf");
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Geist Mono", monospace;
  scroll-behavior: smooth;
}
.loading {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  height: 100vh;
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
#hero {
  width: 100%;
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
}
.navbar {
  height: 100px;
  width: 100%;
  display: flex;
  padding: 0px 100px;
  justify-content: space-between;
  backdrop-filter: blur(1px) saturate(176%);
  -webkit-backdrop-filter: blur(1px) saturate(176%);
  background-color: rgba(29, 29, 29, 0.57);
  border: 1px solid rgba(255, 255, 255, 0.125);
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 9;
}
.navbar .nav-logo img {
  height: 80px;
  width: 220px;
}
.navbar .nav-links ul {
  display: flex;
  list-style: none;
  gap: 40px;
  font-size: 20px;
  color: white;
}
.navbar .nav-links ul li {
  position: relative;
  padding: 5px;
  cursor: pointer;
}
.navbar .nav-links ul i {
  display: none;
}
.navbar .nav-links ul li::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 3px;
  background-color: #fff;
  border-radius: 5px;
  transition: 0.4s ease;
}
.navbar .nav-links ul .active::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #fff;
  border-radius: 5px;
  transition: 0.4s ease;
}
.navbar .nav-links ul li:hover::before {
  width: 100%;
}
.hero {
  position: static;
  height: calc(100vh - 100px);
  /* backdrop-filter: blur(1px) saturate(176%);
  -webkit-backdrop-filter: blur(1px) saturate(176%);
  background-color: rgba(29, 29, 29, 0.57); */
}
.hero-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  margin: 0px 25px;
}
.hero-content h1 {
  font-size: 35px;
  font-family: "Sawah", sans-serif;
}
.hero h3 {
  font-size: 20px;
}
.compare {
  position: relative;
  height: max-content;
  width: 100%;
  background-color: #121212;
  padding: 40px 100px;
}
.compare-slider {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 60px;
}
.comp-slide {
  width: 60%;
}
.services {
  position: relative;
  height: max-content;
  width: 100%;
  padding: 40px 100px;
  background-color: #212121;
}
.services .title {
  backdrop-filter: blur(1px) saturate(176%);
  -webkit-backdrop-filter: blur(1px) saturate(176%);
  background-color: rgba(29, 29, 29, 0.57);
  border: 1px solid rgba(255, 255, 255, 0.125);
}
.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.card {
  --color: red;
  --border-size: 1rem;
  --bg-color: #252525;
  position: relative;
  background-color: var(--bg-color);
  width: 280px;
  margin: 2rem;
  border-radius: var(--border-size);
  color: white;
  display: grid;
  place-content: center;
  padding: 3rem 2rem;
  isolation: isolate;
  overflow: hidden;
  transition: 0.4s ease;
}
.card:hover {
  scale: 1.1;
  cursor: pointer;
}
.card::before,
.card::after {
  content: "";
  position: absolute;
}
.card::before {
  width: 200%;
  height: 200%;
  background-image: conic-gradient(
    var(--color) 0deg,
    transparent 60deg,
    transparent 180deg,
    var(--color) 180deg,
    transparent 240deg
  );
  inset: -50%;
  z-index: -2;
  animation: border 8s linear infinite;
}
@keyframes border {
  to {
    transform: rotateZ(-360deg);
  }
}
.card::after {
  --inset: 0.25rem;
  background-color: #f4f4f4;
  inset: var(--inset);
  border-radius: calc(var(--border-size) - var(--inset));
  z-index: -1;
  transition: all 0.35s linear;
}
.card img {
  margin-inline: auto;
}
.card .title {
  text-align: center;
  font-size: 1.5rem;
  line-height: 1.875rem;
  margin-block: 2.5rem 1.5rem;
  color: var(--color);
  background-color: #e2e2e2;
}
.card .detail {
  line-height: 1.5rem;
  text-align: center;
  opacity: 0.75;
  color: black;
}
.social {
  background-color: #212121;
  padding: 40px 50px;
}
.form {
  margin-top: 20px;
  width: 100%;
  padding: 50px 20px;
  backdrop-filter: blur(1px) saturate(176%);
  -webkit-backdrop-filter: blur(1px) saturate(176%);
  background-color: #f5f7f6;
}
.form input,
select {
  border: 1px solid #cfcfcf;
  padding: 10px;
  width: 40%;
}
.form input:nth-child(5) {
  width: 83%;
}
.form input:nth-child(1),
.form input:nth-child(3) {
  margin-right: 30px;
  margin-bottom: 30px;
}
textarea {
  width: 83%;
  margin-top: 30px;
  border: 1px solid #cfcfcf;
  height: 100px;
  padding: 10px;
  color: #4e4e4e;
  margin-bottom: 30px;
}
.btn {
  width: max-content;
  padding: 10px 30px;
  background-color: red;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 0px;
  margin-bottom: 70px;
}
.footer-card {
  width: 80%;
  backdrop-filter: blur(1px) saturate(176%);
  -webkit-backdrop-filter: blur(1px) saturate(176%);
  background-color: rgba(29, 29, 29, 0.57);
  border: 1px solid rgba(255, 255, 255, 0.125);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  color: white;
  gap: 40px;
  flex-direction: column;
}
.footer-logo img {
  width: 300px;
}
.details {
  width: 50%;
  font-size: 20px;
}
.address {
  width: 50%;
  font-size: 20px;
}
.credit {
  width: 100%;
  background-color: #121212;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}
.credit i {
  padding: 0px 5px;
  color: red;
}
.credit a {
  padding-left: 5px;
  color: #fff;
}
.mob-nav {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #121212;
  width: 100%;
  z-index: 9;
  height: 100vh;
  left: -100%;
  transition: 0.4s ease;
}
.mob-nav-logo img {
  width: 150px;
  height: 80px;
}
.mob-nav ul {
  color: #fff;
  text-align: center;
  padding-top: 15px;
}
.mob-nav ul li {
  padding: 30px 0px;
  font-size: 25px;
}
#title span:nth-child(1) {
  font-size: 22px;
  font-weight: 600;
  font-family: "Sawah", sans-serif;
  letter-spacing: 2px;
}
#title hr {
  margin: 10px 0px;
}
.footer-detail {
  display: flex;
  gap: 50px;
}
.vision {
  width: 100%;
  background-color: #121212;
  padding: 40px 80px;
  color: white;
}
.vision-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 100px;
  padding: 30px 0px;
  border: 1px solid #cfcfcf;
  margin-top: 20px;
}
.vision-content div:nth-child(1) {
  width: 25%;
}
.vision-img img {
  width: 300px;
  height: 300px;
  border-radius: 50%;
}
.vision-detail {
  width: 55%;
  font-size: 20px;
  letter-spacing: 1px;
  line-height: 25px;
}
.taskbar {
  position: fixed;
  z-index: 9999;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 70px;
  background-color: #121212;
  border: 3px solid #cfcfcf;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  display: none;
  justify-content: space-evenly;
  align-items: center;
  padding: 0px 20px;
}
.taskbar div {
  padding: 0px 30px;
  border-right: 1px solid #fff;
}
.taskbar div:last-child {
  border-right: none;
}
.taskbar i {
  font-size: 25px;
  color: white;
}
