@media (max-width: 1150px) {
  .vision-content {
    flex-direction: column;
    gap: 40px;
    text-align: center;
    padding: 10px;
  }
  .vision-detail {
    width: 100%;
    font-size: 18px;
  }
  .vision-content div:nth-child(1) {
    width: 100%;
  }
  .vision-img img {
    width: 250px;
    height: 250px;
  }
}
@media (max-width: 1000px) {
  .navbar {
    padding: 0px 50px;
  }
  .navbar .nav-logo img {
    height: 60px;
    width: 150px;
  }
  .hero-content h1 {
    font-size: 35px;
  }
  .hero-content h3 {
    font-size: 17px;
  }
  .credit {
    display: block;
    text-align: center;
    padding: 20px;
  }
}
@media (max-width: 830px) {
  .navbar {
    padding: 0px 30px;
  }
  .navbar .nav-links ul {
    gap: 30px;
  }
  .hero-content h1 {
    font-size: 30px;
  }
  .hero h3 {
    font-size: 16px;
  }
}
@media (max-width: 750px) {
  .navbar {
    padding: 0px 30px 0px 20px;
  }
  .navbar .nav-links ul li {
    display: none;
  }
  .navbar .nav-logo img {
    height: 70px;
    width: 150px;
  }
  .navbar .nav-links ul i {
    display: block;
    font-size: 30px;
  }
  .hero {
    text-align: center;
  }
  .hero-content {
    left: 0;
    transform: translate(0%, -50%);
  }
  .hero-content h1 {
    font-size: 25px;
  }
  .hero h3 {
    font-size: 20px;
  }
  .compare,
  .services,
  .social,
  .vision {
    padding: 40px 30px;
  }
  #title span:nth-child(1) {
    font-size: 22px;
    margin-bottom: 10px;
  }
  #title hr {
    margin-bottom: 25px;
  }
  .comp-slide {
    width: 100%;
  }
  .form input:nth-child(5) {
    width: 100%;
  }
  .form input:nth-child(1),
  .form input:nth-child(3) {
    margin-bottom: 20px;
  }
  .form input,
  select {
    width: 100%;
    margin-bottom: 20px;
  }
  textarea {
    width: 100%;
    margin-top: 0px;
  }
  .footer {
    padding: 20px 0px;
  }
  .footer-card {
    width: 90%;
    padding: 20px;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .footer-detail {
    display: block;
  }
  .details {
    width: 100%;
    font-size: 20px;
    margin-bottom: 30px;
  }
  .address {
    width: 100%;
    font-size: 20px;
  }
  .taskbar {
    display: flex;
  }
}
@media (min-aspect-ratio: 16/9) {
  #hero {
    width: 100%;
    height: auto;
  }
}
@media (max-aspect-ratio: 16/9) {
  #hero {
    width: auto;
    height: 100%;
  }
}
